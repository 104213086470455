$fa-font-path: "../../../node_modules/@fortawesome/fontawesome-pro/webfonts";

@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";

// Your custom styles
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

body {
  font-family: $primary-font;
  color: $dark;
}

.container {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 1200px) {
  .container {
    max-width: 86rem;
  }
}
// Icons
.fa-2x {
  font-size: 2rem;
}
.twitter-icon {
  background: $bright-primary;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 0.75rem;
  line-height: get-line-height(12px, 20px);
}
.about-icons {
  height: 56px;
}
//Links
a,
a:visited {
  color: $primary-color;
}
a.normal {
  color: inherit;
}
// Spacing
.pt-96 {
  padding-top: 6rem;
}
.pt-16 {
  padding-top: 1rem;
}
.pb-96 {
  padding-bottom: 6rem;
}
@media (min-width: 768px) {
  .pt-md-80 {
    padding-top: 5rem;
  }
  .pb-md-80 {
    padding-bottom: 5rem;
  }
}
.pt-80 {
  padding-top: 5rem;
}
.pb-80 {
  padding-bottom: 5rem;
}
.pb-56 {
  padding-bottom: 3.5rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mb-32 {
  margin-bottom: 2rem;
}

.ml-32 {
  margin-left: 2rem !important;
}

.mr-32 {
  margin-right: 2rem !important;
}
@media (min-width: 576px) {
  .ml-sm-32 {
    margin-left: 2rem !important;
  }

  .mr-sm-32 {
    margin-right: 2rem !important;
  }
}
.pt-12 {
  padding-top: 0.75rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}
// Backgrounds
.dark-white-tint {
  background-color: $dark-white-tint;
}
.black {
  background-color: $dark !important;
}
.light-gray {
  background-color: $light-gray;
}
.primary {
  background-color: $primary-color;
  color: #fff;
  h5 {
    color: #fff;
  }
}

// Type
.font-xs {
}
.small {
  font-size: 0.875rem;
  line-height: get-line-height(14px, 20px);
}
p.large {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: get-line-height(18px, 32px);
}
.gray-text,
.light {
  color: $gray;
}
.primary-text-color {
  color: $primary-color !important;
}

.light-gray-text {
  color: $light-gray !important;
}
// Headings
.small h5 {
  font-size: 0.75rem;
  line-height: get-line-height(12px, 20px);
}
h5 {
  font-size: 0.875rem;
  line-height: get-line-height(14px, 24px);
  font-weight: 700;
  letter-spacing: 0.078rem;
  text-transform: uppercase;
  color: $gray;
  &.small {
    font-size: 0.75rem;
    line-height: get-line-height(12px, 20px);
  }
}
h4 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: get-line-height(18px, 28px);
}
h3 {
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
  font-weight: 500;
}
h2 {
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
  font-weight: 500;
}
h1 {
  font-size: 2rem;
  line-height: get-line-height(32px, 48px);
  line-height: get-line-height(32px, 48px);
  font-weight: 600;
  letter-spacing: 0.1rem;
}
h1.normal-weight {
  font-weight: 400;
}
@media (min-width: 768px) {
  h1 {
    font-size: 2.75rem;
    line-height: get-line-height(44px, 56px);
    line-height: get-line-height(44px, 64px);
    font-weight: 400;
    letter-spacing: 0.15625rem;
  }
  h2 {
  font-size: 1.5rem;
  line-height: get-line-height(24px, 40px);
  font-weight: 500;
}
}
// Home
.banner-bg {
  background-image: url(../img/background.jpg);
  background-size: cover;
  height: 45rem;
}
.about-bg {
  background-image: url(../img/about-bg.jpg);
  background-size: cover;
  height: auto;
}
.logo-list {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  @include media-breakpoint-up(lg) {
    height: 3rem;
  }
  height: 2.25rem;

  &.hm {
    @include media-breakpoint-up(lg) {
      height: 2.625rem;
    }
    height: 1.96875rem;
  }
}
// About

// Images
.about-banner-bg {
  background-image: url(../img/large-about-bg.png);
  background-size: cover;
  background-position: center center;
  height: 35rem;
  color: #fff;
}

.map-bg {
  background-image: url(../img/map-bg.jpg);
  background-size: cover;
  height: 100%;
  background-position: center center;
}
.map-twitter-bg {
  background-image: url(../img/map-twitter-bg.jpg);
  background-size: cover;
  height: 100%;
  background-position: top left;
}
// Buttons
.btn.btn-sm {
  padding: 0.25rem 0rem;
  font-size: 0.875rem;
  width: 4.5rem;
  line-height: get-line-height(14px, 24px);
}
.btn-default.active,
.btn-default:not([disabled]):not(.disabled).active {
  background-color: $primary-color !important;
  color: #fff;
}
.btn-default.tabs {
  background-color: $light-gray-neutral !important;
  color: $gray;
  margin: 0 0.25rem;
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle,
.btn-default:not([disabled]):not(.disabled):active:focus,
.btn-default:not([disabled]):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0;
}
.btn-primary,
a.btn-primary {
  background-color: $primary-color;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:focus,
  &:hover,
  &:active {
    background-color: transparent !important;
    background-color: $dark-primary !important;
    background-color: $bright-primary !important;
    box-shadow: 0 0 0 0;
  }
}
.btn.btn-lg {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
}
.btn {
  text-transform: none;
  box-shadow: none;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  margin: 0px;
  transition: all 0.3s ease-in-out;
}
.btn-outline-primary {
  border: 2px solid $light-gray !important;
  color: $dark-primary !important;
  background-color: #fff !important;
}
.btn[class*="btn-outline-"] {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  &:focus,
  &:hover,
  &:active {
    box-shadow: 0 0 0 0;
    background-color: #fff !important;
    color: $bright-primary;
    border-color: $bright-primary !important;
  }
}
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  box-shadow: 0 0 0 0;
  background-color: $dark-primary !important;
}

.btn-outline-primary:not([disabled]):not(.disabled):active:focus,
.btn-outline-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0;
}
.dropdown-content .md-form {
  padding: 1rem 1rem 0 1rem;
  &:before {
    @extend %fa-icon;
    @extend .fas;
    position: absolute;
    right: 1rem;
    top: 1.75rem;
    color: $primary-color;

    content: fa-content($fa-var-search);
  }
  label {
    top: 0.75rem;
    left: 1rem;
    color: $gray;
  }
}
// Topnav
.navbar-toggler {
}
.navbar-brand a {
  color: $primary-color;
  font-size: 1.5rem;
  font-weight: 700;
}
.navbar {
  box-shadow: 1px 1px 6px 0 rgba($gray, 0.3);
  padding: 0.75rem 2rem;
  z-index: 2;
  z-index: 1080;
}
.navbar-expand-md .navbar-nav .nav-link {
  color: $gray;
  font-weight: 600;
  padding: 0.5rem 0;
  &:focus,
  &:hover,
  &:active {
    color: $primary-color;
  }
  @include media-breakpoint-up(md) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding: 0.75rem;
  }
}

// Tags
.tag {
  box-shadow: 0 0 0 0;
  color: $dark-primary !important;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05375rem;
  font-size: 0.75rem;
  line-height: get-line-height(12px, 20px);
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0.5rem 0;
}

.tag-category {
  background-color: $light-gray-neutral !important;
}

.tag-drug {
  background-color: $light-gray !important;
}

// Footer
footer.page-footer a {
  color: $light-gray;
}

footer.map-footer {
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  box-shadow: 0 -1px 0 0 $light-gray;
  color: $gray;
  & a {
    color: $gray;
  }
  h5 {
    line-height: get-line-height(12px, 24px);
  }
}
@media (max-width: 575px) {
  .map-footer .col-12:first-child {
    display: none;
  }
}

// Card
.card {
  border-radius: 0px;
}

@media (min-width: 992px) {
  .banner-bg .card {
    min-width: 45rem;
  }
}

// Forms
::placeholder {
  color: $gray !important;
}
.search-mobile {
  .form-control {
    border-radius: 0 10rem 10rem 0;
    border: 1px solid $light-gray;
    height: auto;
    border-left: 0px;
    padding-left: 0;
    height: 2.5rem;
    &:focus {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
    }
  }
  .input-group-prepend .input-group-text {
    border-radius: 10rem 0 0 10rem;
    border: 1px solid $light-gray;
    border-right: 0px;
    background: transparent;
    color: $primary-color;
    padding-left: 1rem;
  }
}
.disabled-note span {
  font-size: 0.75rem !important;
  line-height: get-line-height(12px, 20px) !important;
  color: $dark !important;
  padding-top: 0px !important;
  label {
    display: none !important;
  }
}
.dropdown-pb-3 .dropdown-content {
  padding-bottom: 1rem;
}
.dropdown-pt-3 .dropdown-content {
  padding-top: 1rem;
}
.select-wrapper span.caret {
  display: none;
}
.select-wrapper.md-form > ul li label {
  font-size: 0.875rem;
  line-height: get-line-height(14px, 24px) !important;
  margin-bottom: 0px;
}
input.select-dropdown {
  background: url(../img/svg/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: center right;
}
.form-group-wrapper,
.multi-select-group-wrapper {
  margin: 0 0 2rem 0;
  h5 {
    margin: 0px;
  }
  .select-wrapper,
  .md-form {
    margin: 0px;
    .form-control,
    input.select-dropdown {
      border: none;
      box-shadow: inset 0 -1px 0 0 $light-gray;
      line-height: 1.5;
      width: 100%;
      margin: 0;
      height: auto;
      padding: 0.5rem 0;
    }
    .form-control.search {
      width: calc(100% - 1rem);
    }
    .search-icon {
      box-shadow: inset 0 -1px 0 0 $light-gray;
      padding: 0.5rem 0;
      line-height: 1.5;
      cursor: pointer;
      &:hover {
        color: $bright-primary !important;
      }
    }
  }
}
input.rounded {
  border-radius: 3rem !important;
}

.md-form.md-outline.form-lg label {
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
  -webkit-transform: translateY(1rem);
  transform: translateY(1rem);
  color: $gray;
  padding-left: 2rem;
  margin-bottom: 0;

  &.active {
    font-size: 0.875rem;
    transform: translateY(-1.25rem);
    left: 0px;
    padding-left: 0px;
    line-height: get-line-height(14px, 20px);
    color: $gray !important;
  }
}

.md-form.md-outline
  > input[type]:-webkit-autofill:not(.browser-default):not([type="search"])
  + label,
.md-form.md-outline > input[type="time"]:not(.browser-default) + label,
.md-form.md-outline
  > input[type]:-webkit-autofill:not(.browser-default):not([type="search"])
  + label.active,
.md-form.md-outline > input[type="time"]:not(.browser-default) + label.active {
  font-size: 0.875rem;
  transform: translateY(-1.25rem);
  left: 0px;
  padding-left: 0px;
  line-height: get-line-height(14px, 20px);
  color: $gray !important;
}

.md-form.md-outline.form-lg input[type="text"]:focus {
  border-color: $light-gray;
  box-shadow: inset 0 0 0 1px $light-gray;
}

.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 0 0 0;
  border-bottom: 0px solid;
  box-shadow: inset 0 -1px 0 0 $light-gray;
}

.md-form.md-outline.form-lg .form-control.form-control-lg {
  padding: 1rem 2rem;
  background-color: $dark-white-tint;
  border-color: $light-gray;
}

.dropdown-content li > a,
.dropdown-content li > span {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.form-check-input[type="checkbox"] + label:before,
.form-check-input[type="checkbox"]:not(.filled-in) + label:after,
label.btn input[type="checkbox"] + label:before,
label.btn input[type="checkbox"]:not(.filled-in) + label:after {
  border: 2px solid $primary-color;
}

// Pagination
.pagination .page-item {
}
.pagination .page-item .page-link {
  color: $gray;
  font-size: 0.875rem;
  line-height: get-line-height(14px, 20px);
  padding: 0.375rem 0.75rem;
  height: 32px;
  font-weight: 400;
  &.icon {
    font-size: 2rem;
    line-height: get-line-height(32px, 32px);
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    color: $primary-color;
  }
}

.pagination .page-item.active .page-link {
  box-shadow: 0 0 0 0;
  border-radius: 0;
  background-color: transparent;
  color: $primary-color;
  font-weight: 700;
  background-color: $dark-white-tint;
  &:focus,
  &:hover,
  &:active {
    background-color: $dark-white-tint;
    color: $gray;
  }
}
.filter-panel {
  display: none;
}
.list-container .filters-toggle .btn-default.filter-toggle-button {
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: $primary-color;
  border-radius: 10rem;
  background-color: transparent !important;
  font-size: 0.875rem;
  line-height: get-line-height(14px, 24px);
  &.active {
    background-color: $light-gray !important;
  }
  &:hover {
    box-shadow: 0 0 0 0 !important;
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 0 !important;
  }
}
.filters-toggle {
  margin-top: -2rem;
  padding: 1.5rem 0 0 0;
  background: #fff;
  input.search,
  .search-icon {
    padding: 0.75rem 0 !important;
    box-shadow: inset 0 0 0 0 !important;
    color: $primary-color;
  }
}
@media (max-width: 575px) {
  .filters-toggle {
    padding: 0.5rem 0;
  }
}

#togglefilters {
  /* height: 100%;
*/
  overflow: auto;
  padding-top: 1.5rem;
  background: #fff;
  margin-bottom: 2rem;
  border-bottom: 1px solid $light-gray;
}
@media (min-width: 576px) {
  #togglefilters {
    background: $dark-white-neutral;
  }
}
@media (min-width: 1080px) {
  #togglefilters,
  .filters-toggle {
    display: none !important;
  }
  .filter-panel {
    flex: 0 0 20rem;
    height: calc(100vh - 7rem);
    overflow-y: auto;
    box-shadow: 6px 0px 6px -6px rgba($gray, 0.3);
    padding: 2rem;
    z-index: 1;
    z-index: 1079;
    z-index: 1039;
    display: block;
  }
}

.list-container {
  overflow-y: auto;
  height: calc(100vh - 7rem);
  padding: 2rem 0;
  max-width: 45rem;
}
.list-container-inner {
  height: 100%;
}
@media (max-width: 575px) {
  .list-container {
    max-width: 100%;
    width: 100%;
    background: $dark-white-neutral;
  }
}
@media (min-width: 1700px) {
  .list-container {
    padding: 2rem 0;
  }
}

.map-container {
  background-color: $light-gray;
  height: calc(100vh - 7rem);
}
@media (max-width: 575px) {
  .map-container {
    width: 0%;
    max-width: 0%;
  }
}

.overflow-hidden {
  height: calc(100vh - 4.5rem);
}

// List
a.alert-item {
  display: block;
  margin: 0.5rem 0;
  padding: 0.75rem 2rem 0.25rem 2rem;
  transition: all 0.3s linear;
  .alert-title {
    color: $dark;
  }
  &:focus,
  &:hover,
  &:active {
    background-color: $dark-white-neutral;
    cursor: pointer;
    & .alert-title,
    & .arrow-icon {
      color: $primary-color;
    }
  }
}
.twitter-item.alert-item {
  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    cursor: default;
  }
  & .alert-title,
  & .arrow-icon {
    color: $dark;
  }
}
.list-map-toggle {
  display: none;
}
@media (max-width: 575px) {
  .alert-item {
    padding: 0.5rem 0.75rem 0 0.75rem;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px 0 rgba($gray, 0.2);
    margin: 0.75rem;
  }
  .list-map-toggle {
    display: block;
  }
}

// Dropdown
.form-check-input[type="checkbox"] + label,
label.btn input[type="checkbox"] + label {
  padding-left: 1.625rem;
  float: left;
}

.dropdown-content {
  box-shadow: 1px 1px 6px 0 rgba($gray, 0.3);
  min-width: 6.25rem;
  max-height: 40.625rem;

  li {
    line-height: 1.5;
  }
}

.dropdown-content li > a,
.dropdown-content li > span {
  color: $dark;
}

.dropdown-content li:hover,
.dropdown-content li.active {
  background-color: $dark-white-neutral;
  color: $primary-color;
  & span {
    color: $primary-color;
  }
}
.dropdown-content li.active {
  background-color: #fff;
}

input.select-dropdown {
  padding-right: 1rem !important;
  width: calc(100% - 1rem) !important;
}
// Modal
/* fixes the extra space that appears under alert on click event */
.list-container .modal {
  float: left;
  clear: none;
}
.list-container .modal-dialog {
  max-width: none;
  top: 72px !important;
  width: 100%;
  .modal-content {
    box-shadow: 0 0 0 0;
    border-radius: 0px;
  }
  /* bottom padding helps fix bug where you can't scroll if modal content is longer than page */
  .modal-body {
    padding: 1rem 0 10rem 0;
  }
}
.modal-header {
  padding: 1.25rem 0rem;
  border-bottom: 0px;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;

  .close {
    color: $gray;
    font-size: 2rem;
    line-height: 1;
    font-weight: 500;
    opacity: 1;
    span {
      display: block;
    }
  }
  .back-button {
    font-size: 1rem;
    line-height: get-line-height(16px, 32px);
    display: block;
    cursor: pointer;
  }
}
// Map
.mobile-map-wrapper {
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
}
// Map Icon
.map-icon {
  width: 1rem;
  height: 1rem;
  color: #fff;
  font-size: 0.875rem;
  line-height: get-line-height(14px, 22px);
  letter-spacing: 0.0625rem;
  font-weight: 700;
  text-align: center;
  &:hover {
    z-index: 1000 !important;
    &:before {
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      background: $bright-primary;
    }
    &:after {
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      background: $bright-primary;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: -4;
    background: $primary-color;
    border-radius: 50%;
    opacity: 0.3;
    transition: all 0.25s ease;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -3;
    background: $primary-color;
    border-radius: 50%;
    transition: all 0.25s ease;
  }
}

.map-icon.small {
  width: 1.375rem;
  height: 1.375rem;
  &:hover {
    z-index: 2000 !important;
    &:before {
      top: -9px;
      right: -9px;
      bottom: -9px;
      left: -9px;
    }
    &:after {
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
    }
  }
  &:before {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  &:after {
  }
}

.chart-icon {
  width: 0.5rem;
  height: 0.5rem;
  background: rgba($dark, 0.7);
  border-radius: 50%;
  &.small {
    width: 0.75rem;
    height: 0.75rem;
  }
  &.medium {
    width: 0.75rem;
    height: 0.75rem;
  }
  &.large {
    width: 1rem;
    height: 1rem;
  }
}

// make sure the map markers stay bright after being clicked
.map-icon.active {
  border-radius: 50%;
  background: $bright-primary;
}

.map-icon.medium {
  width: 1.75rem;
  height: 1.75rem;
  line-height: get-line-height(14px, 28px);
  &:hover {
    z-index: 2000 !important;
    &:before {
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
    }
    &:after {
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
    }
  }
  &:before {
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
  &:after {
  }
}

.map-icon.large {
  width: 2.125rem;
  height: 2.125rem;
  line-height: get-line-height(14px, 34px);
  &:hover {
    z-index: 2000 !important;
    &:before {
      top: -11px;
      right: -11px;
      bottom: -11px;
      left: -11px;
    }
    &:after {
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
    }
  }
  &:before {
    top: -7px;
    right: -7px;
    bottom: -7px;
    left: -7px;
  }
  &:after {
  }
}

.react-mapbox-ac-menu {
  width: auto;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: -1.3rem;
}

.react-mapbox-ac-input {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  background-color: #f0f6f7;
  border-color: #cfe2e6;
}

.react-mapbox-ac-input:focus {
  border-color: #cfe2e6;
  box-shadow: inset 0 0 0 1px #cfe2e6;
}

.react-mapbox-ac-suggestion {
  font-size: 18px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid #cef2e0;
}

.react-mapbox-ac-suggestion:hover {
  background-color: rgba(85, 136, 170, 0.445);
}
.mapboxgl-popup .mapboxgl-popup-content {
  border-radius: 0;
  padding: 0;
  box-shadow: 0 0 0 0;
  overflow-y: scroll;

  h2 {
    background: $bright-primary;
    color: #fff;
    font-weight: 400;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: get-line-height(18px, 24px);
    line-height: 1.333333333333;
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
  }
}
.mapboxgl-popup {
  z-index: 2001 !important;
  max-width: 40rem;
  width: calc(100% - 2rem);
  box-shadow: 1px 1px 6px 0 rgba($gray, 0.4);
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: 56px;
  cursor: auto;

  .alert-title {
    a {
      color: $dark;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    transition: all 0.3s linear;
    svg {
      color: $gray;
      /*   color: #fff;
    */
    }
    &:hover {
      color: $primary-color;
      cursor: pointer;
      svg {
        color: $primary-color;
      }
    }
  }
  .twitter-alert.alert-title {
    color: $dark;
    cursor: default;
  }
  .popup {
    padding: 0 1.5rem 1.5rem 1.5rem;
    margin-top: 3.5rem;
    max-height: calc(100vh - 326px);
    overflow-y: scroll;
    .scroll-content {
      margin-top: 1.5rem;
    }
  }
}

.popup-container {
  max-height: calc(100% - 110px);
  height: auto;
}

.chart-popup {
  color: #fff;
  width: 300px;
  margin: 0;
  box-shadow: 0 0 0 0;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: rgba($dark, 0.95) !important;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgba($dark, 0.95) !important;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: rgba($dark, 0.95) !important;
}
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    border-bottom-color: rgba($dark, 0.95) !important;
}
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: rgba($dark, 0.95) !important;
}

.chart-popup > .mapboxgl-popup-content {
  background-color: transparent;
  background-color: rgba($dark, 0.95);
}

.chart-popup-content {
  background-color: transparent;
  color: #fff;
  margin: 1rem;
  label {
    margin-bottom: 0.75rem;
  }
  .location {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.mapboxgl-popup-close-button {
  font-size: 1.25rem !important;
  font: 500 1.25rem system-ui;
  color: #fff;
  z-index: 100;
  line-height: get-line-height(20px, 24px);
  padding: 0.5rem 0.75rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: $dark-white-tint;
  }
}

// Carousel
.carousel-multi-item {
  padding-bottom: 2rem;
  margin-bottom: 0;
}
.carousel-multi-item .controls-top {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;

  i.fa-chevron-right {
    @extend %fa-icon;
    @extend .fal;
    color: $gray;

    &:before {
      content: fa-content($fa-var-arrow-circle-right);
    }
  }
  i.fa-chevron-left {
    @extend %fa-icon;
    @extend .fal;
    color: $gray;

    &:before {
      content: fa-content($fa-var-arrow-circle-left);
    }
  }
  .btn-floating {
    box-shadow: 0 0 0 0;
    background: #fafafa;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;

    i {
      line-height: 1;
      font-size: 2rem;
    }
  }
}

.carousel-multi-item .carousel-indicators {
  margin-bottom: 0;
  left: 0;
  right: auto;
  justify-content: start;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
  list-style-type: none;
  counter-reset: number;
  li {
    margin-bottom: 0;
    border: 0px;
    text-indent: 0;
    margin: 0;
    font-size: 0.875rem;
    line-height: get-line-height(14px, 32px);
    color: $gray;
    opacity: 1;
    background-color: transparent;
    height: 2rem;
    width: 2rem;
    max-width: 2rem;
    text-align: center;
    &:before {
      counter-increment: number;
      content: counter(number);
    }
  }
}

.carousel-multi-item .carousel-indicators .active {
  height: 1.25rem;
  width: 1.25rem;
  max-width: 1.25rem;
  background-color: $dark-white-tint;
  color: $primary-color;
  font-weight: 700;
  border-radius: 0;
  height: 2rem;
  width: 2rem;
  max-width: 2rem;
}

// Grid
.large-gutter {
  .row {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

// Charts page
.chart-select.form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
}
.circle-outline {
  display: inline-block;
  border: 2px solid red;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;
  text-align: center;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 3;
  &.drugmap {
    border-color: $bright-primary;
  }
  &.cdc {
    border-color: $bright-red;
  }
}
.chart {
  @media (min-width: 900px) {
    height: 35rem;
  }
  height: 25rem;
}

.chart-dropdown {
  margin-top: 0px;
}

.chart-select {
  margin-top: 1rem;
}

.chart-label {
  margin-right: 1rem !important;
}

.chart-legend {
  display: flex;
  flex-direction: column;
  @media (min-width: 780px) {
    flex-direction: row;
  }
  margin-top: 1rem;

  @media (min-width: 780px) {
    &-inner {
      margin-right: 4rem;
    }
  }

  .legend-label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .legend-number {
    float: left;
  }
}
.legend-block-wrapper {
  float: left;
  box-shadow: 1px 1px 6px 0 rgba($dark, 0.4);
  &.legend-circles {
    box-shadow: 0 0 0 0;
    display: flex;
    align-items: center;
    height: 1.5rem;
  }
}
.legend-block {
  float: left;
  height: 1.5rem;
  @media (min-width: 780px) {
    width: 3rem;
  }
  width: 1.5rem;
  background-color: #fff;
  display: block;
  &.yellow {
    background-color: $yellow !important;
  }
  &.orange {
    background-color: $orange !important;
  }
  &.bright-red {
    background-color: $bright-red !important;
  }
  &.red {
    background-color: $red !important;
  }
  &.circle {
    background-color: rgba($dark, 0.7);
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    &.medium {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 0.75rem;
    }
    &.large {
      width: 1rem;
      height: 1rem;
    }
  }
}
