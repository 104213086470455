// Grid 
$grid-gutter-width: 2rem !important;
// Colors
$primary-color: #008096;
$dark: #00343d;
$dark-primary: #005766;
$gray: #708f94;
$bright-primary: #29b4cc;
$bright-primary-2: #66e9ff;
$light-gray: #cfe2e6;
$light-gray-2: #e9f3f5;
$dark-white-tint: #f0f6f7;
$dark-white-neutral: #f5f7f7;
$light-gray-neutral: #E8EAEB;
$yellow: #FFE9A6;
$orange: #FAB17D;
$bright-red: #FE6D72;
$red: #AD2B3C;

// Font
$primary-font: "Cabin", sans-serif;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
) !important;

